import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
//these two imports only for shorturls
import { navigate } from "gatsby";
import axios from "axios";


const getPropurl = async (handler, crmid) => {
	let result = []

	var data = JSON.stringify({
		"query": "query($crm_id:[String]) { properties(where: { crm_id: $crm_id, publish: true}) { id, slug, search_type, department } }",
		"variables": {"crm_id": [crmid] }
	  });
	var config = {
		method: 'post',
		url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
		},
		data : data
	};
	const res = await axios(config);
	result[0] = JSON.stringify(res.data);
	handler(result);
}

const Page404 = (props) => {
  const [showtemplate, setShowtemplate] = React.useState(false);
	const [propdata, setPropdata] = React.useState('');

	React.useEffect(() => {
		let regex = "\[a-zA-Z]{3}[0-9]{6}";

		if(props?.location?.pathname){
			let crm_id = (props?.location?.pathname).match(regex) ;

			if(typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata){
				getPropurl(setPropdata, crm_id[0]);
			} else if(propdata){
				let property_data = JSON.parse(propdata);
				let property_arr = property_data.data.properties;
				if(property_arr.length > 0) {
					let property = property_arr[0];

            if(property.department == 'residential') {
              if(property.id) {
                if (property.search_type == 'sales') {
                navigate(`/property-for-sale/${property.slug}-${property.id}`,{ replace: true });
                }
                else if (property.search_type == 'lettings') {
                navigate(`/property-to-rent/${property.slug}-${property.id}`,{ replace: true });
                }
              }
            }
            // if(property.department == 'commercial') {
            //   if(property.id) {
            //     if (property.search_type == 'sales') {
            //     navigate(`/commercial-property-for-sale/${property.slug}-${property.id}`,{ replace: true });
            //     }
            //     else if (property.search_type == 'lettings') {
            //     navigate(`/commercial-property-to-rent/${property.slug}-${property.id}`,{ replace: true });
            //     }
            //   }
            // }
            if(property.department == 'new_developments') {
              if(property.id) {
                if (property.search_type == 'sales') {
                navigate(`/new-home-for-sale/${property.slug}-${property.id}`,{ replace: true });
                }
                else if (property.search_type == 'lettings') {
                navigate(`/new-home-to-rent/${property.slug}-${property.id}`,{ replace: true });
                }
              }
            }
				} else {
					setShowtemplate(true);
				}
			} else {
				setShowtemplate(true);
			}
		}
	  },[propdata]);


  return (
    <React.Fragment>
      {showtemplate &&
        <div id={`body-bg-default`} className={`wrapper fof-page`}>
        <div className="inner-header"><Header /></div>
          <section className="errorpage">
            <Container>
              <Row className="d-flex justify-content-center bd-highlight mb-3 text-center">
                <Col md="9">
                  <h1>We’re sorry, the page you were looking for cannot be found.</h1>
                  <p>This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
                  <div class="btn-wrapper">
                    <Link to="/property-valuation" class="btn btn-primary">Value my property</Link>
                    <Link to="/" class="btn btn-primary share">Homepage</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      }
    </React.Fragment>
  )
}
export default Page404;
